import { getOrigins, getBusinessSources, getSources } from './sources'
import { EventCenter } from './eventCenter'
import { nextIdle, isArrayPixel, isPixel } from './preprocessing/utils'
import { isFunction, isPromise } from '@shein/common-function'
import { __tpes__ } from './tpes'
class TPM {
  #status = {
    ONLOAD: false,
    DEPS: false,
    FIRE_ALL: false,
    SDK: false
  }
  #loadPixel = {
    List: [],
    Count: 0
  }
  marketings = {}
  preprocessingData = null

  get OL_MODE() {
    return this.preprocessingData.ENV.PRODUCTION || sessionStorage.tpm === 'testmode'
  }
  set OL_MODE(val) {
    sessionStorage.tpm = val ? 'testmode' : ''
    location.reload()
  }

  get SHOW_LOG() {
    return sessionStorage.tpm_log === 'show'
  }
  set SHOW_LOG(val) {
    sessionStorage.tpm_log = val ? 'show' : ''
    location.reload()
  }

  constructor(dependencies) {
    this.TPES = __tpes__
    this.eventCenter = new EventCenter(this)
    this.preprocessingData = Object.prototype.toString.call(dependencies) === '[object Object]' ? dependencies : {}
    this.initSources(getBusinessSources())
    // 如果在添加事件监听器前 load 事件已经被触发
    if (document.readyState === 'complete') {
      setTimeout(() => {
        requestIdleCallback(() => {
          this.syncStatus('ONLOAD')
        })
      }, 0)
    } else {
      window.addEventListener('load', () => this.syncStatus('ONLOAD'))
    }
  }

  syncStatus(status) {
    this.#status[status] = true
    if (this.#status.ONLOAD === true) {
      this.#status.ONLOAD = 'done'
      this.preconnect()
      this.load()
    }

    if (this.#status.SDK === true && this.#status.FIRE_ALL === true) {
      this.#status.SDK = this.#status.FIRE_ALL = 'done'
      window.dispatchEvent(new Event('TPM_SDK_Loaded'))
    }
  }

  preconnect() {
    if (!this.OL_MODE) return
    const domDataList = getOrigins().map(origin => ({
      TPM_Ele_Type: 'link',
      rel: 'preconnect',
      href: origin
    }))

    this.appendDom({
      domDataList,
      parentEle: 'head',
      comment: 'preconnect'
    })
  }

  async load() {
    const sources = await getSources(this.OL_MODE)
    this.initSources(sources)

    this.watchSDKFinish()

    this.appendDom({
      domDataList: this.#loadPixel.List,
      comment: 'load'
    })

    await this.eventCenter.fireAll()
    this.syncStatus('FIRE_ALL')
  }

  initSources(sources) {
    const { marketings, pixelList: domDataList } = Object.values(sources).reduce(({ marketings, pixelList }, next) => {
      const marketingObj = new next(this.preprocessingData)
      marketings[next.name] = marketingObj

      let pixels
      if (marketingObj.active !== false) {
        const { apolloConfig, ENV } = this.preprocessingData
        if (apolloConfig[next.name]?.[ENV.SiteUID] || apolloConfig[next.name]?.disabledAll) {
          marketingObj.shutdownByApollo = true
        } else {
          this.eventCenter.subscribe(next.name, marketingObj)
          pixels = marketingObj.init?.()
        }
      }

      return {
        marketings,
        pixelList: isPixel(pixels) ? pixelList.concat(pixels) : pixelList
      }
    }, { marketings: {}, pixelList: [] })

    Object.assign(this.marketings, marketings)
    this.#loadPixel.List = this.#loadPixel.List.concat(domDataList)
  }

  watchSDKFinish() {
    this.#loadPixel.List.forEach(_ => {
      if (_.TPM_Ele_Type !== 'script') return
      if (_.onload) _.TPM_PixelCallback = _.onload
      ++this.#loadPixel.Count

      _.onload = () => {
        _.TPM_PixelCallback?.()
        this.log(`SDK load (${--this.#loadPixel.Count} left)`, _)
        this.#loadPixel.Count || this.#status.SDK === 'done' || (this.log('SDK load complete'), this.syncStatus('SDK'))
      }
    })
    setTimeout(() => {
      if (this.#status.SDK === 'done') return
      this.log('SDK load timeout')
      this.syncStatus('SDK')
    }, 5000)
  }

  async appendDom({ domDataList = [], parentEle = 'body', comment = '' }) {
    domDataList = this.checkPixel(domDataList, parentEle, comment)
    if (!domDataList.length) return

    const anchorId = `TPM-anchor-${comment}`
    let anchor = document.getElementById(anchorId)
    const fragment = document.createDocumentFragment()

    !anchor && fragment.appendChild(document.createComment(` #TPM - Start | ${comment} `))

    const { PUBLIC_CDN } = this.preprocessingData.ENV
    domDataList.forEach(domData => {
      const ele = document.createElement(domData.TPM_Ele_Type)
      Object.keys(domData).forEach(key => {
        switch (key) {
          case 'TPM_Ele_Type':
            domData.TPM_Ele_Type === 'script'
              ? ele.async = true
              : domData.TPM_Ele_Type !== 'link' && (ele.style.display = 'none')
            break
          case 'TPM_Config':
          case 'TPM_PixelCallback':
            break
          case 'src':
          case 'href':
            ele[key] = !domData.TPM_Config?.CDN || !!PUBLIC_CDN
              ? 'https:' + domData[key]
              : domData[key]
            break
          default:
            ele[key] = domData[key]
            break
        }
      })
      fragment.appendChild(ele)
    })

    if (!anchor) {
      anchor = document.createElement('span')
      anchor.id = anchorId
      anchor.style.display = 'none'

      fragment.appendChild(anchor)
      fragment.appendChild(document.createComment(` #TPM -  End  | ${comment} `))

      comment !== 'preconnect' && await nextIdle()
      this.log('appendDom', { comment })
      document[parentEle].appendChild(fragment)
    } else {
      await nextIdle()
      this.log('appendDom', { comment })
      document[parentEle].insertBefore(fragment, anchor)
    }
  }

  checkPixel(domDataList, parentEle, comment) {
    // 允许直接返回,异步返回,回调返回,异步回调返回
    return domDataList.filter(_ => {
      // 先处理回调
      if (isFunction(_)) _ = _()
      // 承接异步
      if (isPromise(_)) {
        _
          .then(__ => isArrayPixel(__) && this.appendDom({ domDataList: __, parentEle, comment: `async ${comment}` }))
          .catch(__ => this.log('checkPixel catch error', { error: __, comment: `async ${comment}` }))
        return false
      }
      if (isArrayPixel(_)) {
        this.appendDom({ domDataList: _, parentEle, comment: `func ${comment}` })
        return false
      }
      return !!_.TPM_Ele_Type
    })
  }

  publish(event, data) {
    /** preformat data here first */
    this.eventCenter.publish(event, data)
  }

  async run(payload) {
    try {
      await nextIdle()
      return this.parseCustomReturns(await this._runImmediate(payload, true), true)
    } catch (error) {
      console.error(error)
      return { message: error }
    }
  }

  runImmediate(payload) {
    try {
      return this.parseCustomReturns(this._runImmediate(payload))
    } catch (error) {
      console.error(error)
      return { message: error }
    }
  }

  _runImmediate({ marketing, method, params }, async) {
    marketing = this.marketings[marketing]
    const errorMsg = !marketing
      ? 'ERROR: marketing does not exist.'
      : marketing.active === false || marketing.shutdownByApollo === true
        ? 'FAIL: marketing is disabled.'
        : !marketing[method]
          ? 'ERROR: method not fount.'
          : ''
    if (errorMsg) throw new Error(errorMsg)
    const returns = marketing[method](params)
    if (!async && isPromise(returns)) throw new Error('WARNING: promise returns detected, use TPM.run instead. returns would be ignored.')
    return returns
  }

  parseCustomReturns(returns, async) {
    const { datas, pixels } = returns || {}
    isPixel(pixels) && this.appendDom({
      domDataList: pixels,
      comment: async ? 'async custom' : 'custom'
    })
    return { datas, message: 'success' }
  }

  log() {
    this.SHOW_LOG && console.log('[TPM]', ...arguments)
  }
}

window.TPM = new TPM({
  ENV: {
    PRODUCTION: gbCommonInfo.NODE_SERVER_ENV === 'production',
    SiteUID: gbCommonInfo.SiteUID,
    PUBLIC_CDN: gbCommonInfo.PUBLIC_CDN,
  },
  apolloConfig: gbCommonInfo.TPM_BLACKLIST,
})
