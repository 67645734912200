// import apiReport from 'public/src/pages/common/apiReport'
// const { SiteUID } = gbCommonInfo
import { asyncLoadFile } from '@shein/common-function'
const { NODE_SERVER_ENV } = gbCommonInfo

const afterpaySDK = ['debug', 'localhost'].includes(NODE_SERVER_ENV) ? 'https://portal.sandbox.afterpay.com/afterpay.js' : 'https://portal.afterpay.com/afterpay.js'
export class InitChannelSdk {
  static name = 'InitChannelSdk'


  #SDK_MAP = {
    'pp-client': 'https://js.braintreegateway.com/web/3.85.2/js/client.min.js',
    'pp-checkout': 'https://js.braintreegateway.com/web/3.85.2/js/paypal-checkout.min.js',
    'pp-data-collector': 'https://js.braintreegateway.com/web/3.85.2/js/data-collector.min.js',
    'pp-venmo': 'https://js.braintreegateway.com/web/3.85.2/js/venmo.min.js',
    'klarna': 'https://x.klarnacdn.net/kp/lib/v1/api.js',
    'gp': 'https://pay.google.com/gp/p/js/pay.js',
    'afterpay-cashapp': afterpaySDK
  }

  #PromiseStatus = {}

  #SdkLoadPromiseMap = {}

  #PPGA_SDKS = ['pp-client', 'pp-data-collector', 'pp-checkout']

  #PPBNPL_SDKS = ['pp-client', 'pp-data-collector', 'pp-checkout']
    
  #VENMO_SDKS = ['pp-client', 'pp-data-collector', 'pp-venmo']

  #KLARNA_SDKS = ['klarna']

  #GP_SDKS = ['gp']
    
  #AFTERPAY_SDKS = ['afterpay-cashapp']

  #createAsyncLoadFilePromise (sdkName) {
    const sdk = this.#SDK_MAP[sdkName]
    const status = this.#PromiseStatus[sdkName]
    if (status === 'fulfilled') return Promise.resolve(true)
    else if (this.#SdkLoadPromiseMap[sdkName] && status === 'pending') return this.#SdkLoadPromiseMap[sdkName]
    this.#PromiseStatus[sdkName] = 'pending'
    return this.#SdkLoadPromiseMap[sdkName] = asyncLoadFile({
      label: 'script',
      attrs: {
        src: sdk,
        id: `channel-${sdkName}`
      }
    })
  }

  #checkSDKLoaded (sdkNames) {
    return sdkNames.every(sdkName => this.#PromiseStatus[sdkName] === 'fulfilled')
  }

  #createLoadPPGASDKPromise () {
    return Promise.allSettled(this.#PPGA_SDKS.map(sdkName => this.#createAsyncLoadFilePromise(sdkName)))
  }

  #createLoadPPBNPLSDKPromise () {
    return Promise.allSettled(this.#PPBNPL_SDKS.map(sdkName => this.#createAsyncLoadFilePromise(sdkName)))
  }

  #createLoadVenmoSDKPromise () {
    return Promise.allSettled(this.#VENMO_SDKS.map(sdkName => this.#createAsyncLoadFilePromise(sdkName)))
  }

  #createLoadKlarnaSDKPromise () {
    return Promise.allSettled(this.#KLARNA_SDKS.map(sdkName => this.#createAsyncLoadFilePromise(sdkName)))
  }


  async _initPPGASdk () {
    if (this.#checkSDKLoaded(this.#PPGA_SDKS)) {
      return { datas: true }
    }

    const result = await this.#createLoadPPGASDKPromise()
    result.forEach((res, index) => {
      this.#PromiseStatus[this.#PPGA_SDKS[index]] = res.status
    })
    return { datas: true }
  }

  async _initPPBNPLSdk () {
    if (this.#checkSDKLoaded(this.#PPBNPL_SDKS)) {
      return { datas: true }
    }

    const result = await this.#createLoadPPBNPLSDKPromise()
    result.forEach((res, index) => {
      this.#PromiseStatus[this.#PPBNPL_SDKS[index]] = res.status
    })
    return { datas: true }
  }

  async _initVenmoSdk () {

    if (this.#checkSDKLoaded(this.#VENMO_SDKS)) {
      return { datas: true }
    }
    const result = await this.#createLoadVenmoSDKPromise()
    result.forEach((res, index) => {
      this.#PromiseStatus[this.#VENMO_SDKS[index]] = res.status
    })
    console.log('result', result)
    return { datas: true }
  }

  async _initKlarnaSdk () {
    if (this.#checkSDKLoaded(this.#KLARNA_SDKS)) {
      return { datas: true }
    }

    const result = await this.#createLoadKlarnaSDKPromise()
    result.forEach((res, index) => {
      this.#PromiseStatus[this.#KLARNA_SDKS[index]] = res.status
    })
    return { datas: true }
  }

  async _initGPSdk () {
    if (this.#checkSDKLoaded(this.#GP_SDKS)) {
      return { datas: true }
    }

    const result = await Promise.allSettled(this.#GP_SDKS.map(sdkName => this.#createAsyncLoadFilePromise(sdkName)))
    result.forEach((res, index) => {
      this.#PromiseStatus[this.#GP_SDKS[index]] = res.status
    })
    return { datas: true }
  }

  async _initAfterpaySdk () {
    if (this.#checkSDKLoaded(this.#AFTERPAY_SDKS)) {
      return { datas: true }
    }

    const result = await Promise.allSettled(this.#AFTERPAY_SDKS.map(sdkName => this.#createAsyncLoadFilePromise(sdkName)))
    result.forEach((res, index) => {
      this.#PromiseStatus[this.#AFTERPAY_SDKS[index]] = res.status
    })
    return { datas: true }
  }
}
