// 判断是否是关联订单号，U开头为关联订单号，G开头为老订单号
exports.isRelationBillno = billno => {
  if (typeof billno !== 'string') return false
  return typeof billno.startsWith === 'function' ? billno.startsWith('U') : billno.indexOf && billno.indexOf('U') === 0
}

// 将数组转化为可存储为kv对的map对象
// * 浅拷贝
exports.generateKVMapFromArr = (source, key) => {
  const map = Object.create(null)
  if (!Array.isArray(source) || !source.length) {
    return map
  }
  if (typeof key !== 'string' || !key) {
    return map
  }

  source.forEach(current => {
    if (!current) {
      return
    }
    const KEY = current[key]
    if (!KEY) {
      return
    }
    map[KEY] = current
  })

  return map
}

// 特殊log
exports.generateConsole = (title, content = '') => {
  let str = ''
  if (content && `${content}`.length > 0 && `${content}`.length <= 225) {
    for (let i = 0; i < `${content}`.length; i++) {
      str += ' '
    }
  }
  str += content
  console.log(`%c🚀 ${title} 🚀 %s `, 'font-family:sans-serif;font-size:0.8em; color:white; font-weight:bold; background: linear-gradient(#17a6ea 26%, #4FCA2D); border-radius: 4px; padding: 4px', str)
}

// 提取bff.abt返回结果
exports.pickAbtResult = (data)=>{
  return Object.fromEntries(Object.entries(data).map(([key, value]) => {
    return [key, value.param]
  }))
}

/**
 * hex转rgba
 * @param {*} hex 
 * @param {*} alpha 
 * @returns 
 */
exports.hexToRGBA = (hex, alpha) => {
  if (!/^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex)) { 
    // throw new Error('Invalid HEX') 
    return ''
  }
  const chunkSize = Math.floor((hex.length - 1) / 3)
  const hexArr =  hex.slice(1)?.match(new RegExp(`.{${chunkSize}}`, 'g'))
  const [r, g, b, a] = hexArr.map((hexStr) => {
    return parseInt(hexStr.repeat(2 / hexStr.length), 16)
  })

  const getAlphafloat = (a, alpha) => {
    if (typeof a !== 'undefined') { return a / 255 }
    if ((typeof alpha != 'number') || alpha < 0 || alpha > 1){
      return 1
    }
    return alpha
  }

  return `rgba(${r}, ${g}, ${b}, ${getAlphafloat(a, alpha)})`
}
