// 全局变量
const { lang, currencies, WEB_CLIENT } = gbCommonInfo
import { getCookie, transformImg, isFunction, isUnDef } from '@shein/common-function'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

var site = lang

/*
 *price:沒有格式的本地价格
 *currencyTitle:币种 比如：'EUR', 'RUB', 'TRY', 'VND',传了可以不传currency
 *currency:货币对象，传了可以不传currencyTitle
 */
var GB_transform_price_symbol = (function () {
    return function (price, currencyTitle, currency) {
      price = Number(price)
      if (isNaN(price)) {
        return price
      }
      if (!currency) {
        if (!currencyTitle) {
          site = $('meta[charset="utf-8"]').attr('data-lang')
          currencyTitle = UserInfoManager.get({ key: 'currency', actionType: 'common/variable' })
          if (!currencyTitle) {
            return price
          }
        }
        try {
          const _currencies = currencies[lang]
          currency = _currencies[currencyTitle]
          if (!currency) {
            return price
          }
        } catch (e) { /* empty */ }
      } else {
        if (currency.code) {
          currencyTitle = currency.code // 以currency里的货币为准，但没啥用
        }
      }
      // decimal_place货币符号，dec_point货币小数点，thousands_sep货币千位符
      var formatObj = {
        decimalPlace: typeof currency.decimal_place !== 'undefined' && currency.decimal_place !== null ? Number(currency.decimal_place) : 2,
        decimalPoint: typeof currency.dec_point !== 'undefined' && currency.dec_point !== null ? currency.dec_point : '.',
        thousandPoint: typeof currency.thousands_sep !== 'undefined' && currency.thousands_sep !== null ? currency.thousands_sep : ''
      }
      price = price.toFixed(formatObj.decimalPlace) // 保留位数
      var priceArr = price.toString().split('.')
      price = priceArr[0].split('')
      for (var i = 1; i < price.length - 2; i++) {
        if ((price.length - i) % 3 == 0) {
          price.splice(i, 0, formatObj.thousandPoint)
          i++
        }
      }
      // 拼接千分号
      price = price.join('') + (priceArr.length == 2 ? formatObj.decimalPoint + priceArr[1] : '')
      // 拼接左右符号
      price = currency.symbol_left + price + currency.symbol_right
      // console.log('GB_transform_price_symbol:'+currencyTitle+';'+price)
      return price
    }
})()

// 购物流程 traceid 追踪
const traceidComponents = {}
var gbExposeTraceid = function (type, payload) {
  try {
    var defaultTraceid = `${WEB_CLIENT == 'shein' ? 'spwa`' : 'rwm`'}${Date.now()}\`${getCookie('cookieId') || ''}`
    var traceid = ''
    var traceids = null
    try {
      traceids = JSON.parse(sessionStorage.getItem('saTraceids')) || {}
    } catch (e) {
      traceids = {}
    }
    switch (type) {
      case 'setComponent':
        if (!traceidComponents[payload.componentName]) {
          traceid = payload.traceid || defaultTraceid
          if (payload.params) traceid += '`' + payload.params
          traceidComponents[payload.componentName] = traceid
        } else {
          traceid = traceidComponents[payload.componentName]
        }
        break
      case 'getComponent':
        if (!traceidComponents[payload.componentName]) {
          traceidComponents[payload.componentName] = defaultTraceid
        }
        traceid = traceidComponents[payload.componentName]
        break
      case 'refreshComponent':
        if (traceidComponents[payload.componentName]) traceidComponents[payload.componentName] = null
        break
      case 'setProduct':
        traceid = payload.traceid || defaultTraceid
        if (payload.params) traceid += '`' + payload.params
        traceids[payload.goods_id] = traceid
        break
      case 'getProduct':
        if (!traceids[payload.goods_id]) {
          traceids[payload.goods_id] = defaultTraceid
        }
        traceid = traceids[payload.goods_id]
        break
    }
    try {
      sessionStorage.setItem('saTraceids', JSON.stringify(traceids))
    } catch (e) {
      console.error(e)
    }

    return traceid
  } catch (error) {
    console.error(error)
  }
}

// 图片转换(之前老的transformImg传参方式与common-function中的不同，这里做一层兼容)
function transformImgGlobal (img, useWebp = true, isImgCenter = false) {
  return transformImg({ img, useWebp, isImgCenter })
}

/**
 * 获取货币转换规则
 */
function getCurrencyRule () {
  if (!window?.gbCommonInfo) return null
  const {
    currencies,
    lang,
    currency,
    appLanguage
  } = window.gbCommonInfo

  const currencyRule = currencies[lang][currency]
  const { separator_multi_resp_list = [] } = currencyRule
  const {
    dec_point_symbol,
    thousands_sep_symbol
  } = separator_multi_resp_list.filter(item => item.language_flag === appLanguage)[0] || {}
  return {
    ...currencyRule,
    ...(dec_point_symbol && { dec_point: dec_point_symbol, dec_point_symbol }), // 设置dec_point是为了兼容旧的货币转换方法GB_transform_price_symbol
    ...(thousands_sep_symbol && { thousands_sep: thousands_sep_symbol, thousands_sep_symbol }) // 设置thousands_sep是为了兼容旧的货币转换方法GB_transform_price_symbol
  }
}

// 三端统一跳转函数 配置后台有多个地方页面使用 所以写在这里
// 三端统一跳转函数 配置后台有多个地方页面使用 所以写在这里
// 三端统一跳转函数 配置后台有多个地方页面使用 所以写在这里
function uniteJump (e) {
  // 适配后台配置三端统一跳转的链接
  // 参考：wiki.pageId=499254870
  e?.preventDefault?.()
  e?.stopPropagation?.()
  var dom = $(e.target)
  if (!dom.attr('weblink') && dom.parents('a').length && !dom.prop('href')) {
    dom = dom.parents('a')
  }
  var mLink = dom.attr('mLink') 
  var weblink = mLink ? mLink : dom.attr('weblink')
  var jumpType = dom.prop('target')
  if (!weblink) {
    weblink = dom.prop('href')
  }
  if (isFunction(window.sa)) {
    let welcomeDom = dom.parents('.j-welcome-data')
    let welcomeData = {}
    if (welcomeDom?.length) {
      const contact_us_type = welcomeDom.data('contact_us_type')
      welcomeData = {
        dialog_id: welcomeDom.data('dialog_id') || '',
        chat_id: welcomeDom.data('chat_id') || '',
        repository_id: welcomeDom.data('repository_id') || '',
        standard_question: welcomeDom.data('standard_question') || '',
        related_question_type: welcomeDom.data('related_question_type') || '',
        contact_us_type: isUnDef(contact_us_type) ? '' : contact_us_type,
        is_in_tag: welcomeDom.data('is_in_tag') || 0,
        is_default_tag: welcomeDom.data('is_default_tag') || 0,
        is_user_tag: '',
      }
    }
    const activity_param = {
      link_name: dom.attr('pageto') || '',
      ...welcomeData
    }
    sa('send', {
      activity_name: 'click_sheinlink',
      activity_param
    })
  }
  
  if (jumpType) {
    window.open(weblink)
  } else {
    location.href = weblink
  }
}


window.uniteJump = uniteJump
window.gbExposeTraceid = gbExposeTraceid
window.transformImg = transformImgGlobal
window.GB_transform_price_symbol = GB_transform_price_symbol
window.getCurrencyRule = getCurrencyRule
