
// 第三方上报异步文件加载
import './load_third'
import './ga'
import DeclarativeAnalysis from './declarative'

$(() => {
  const declarativeAnalysis = new DeclarativeAnalysis()
  declarativeAnalysis.init()
})
