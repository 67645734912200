/* globals */
/**
 * 整合全局弹窗的出现顺序, 在关闭上一个弹窗之后下一个弹窗才会出现
 * 美国短信弹窗 > ip跳转弹窗 > 隐私政策弹窗 > 券包 > 回访卡券 > 快速注册弹窗 > 全民营销弹窗
 * */
import Vue from 'vue'
import './ip_jump'
import { getQueryString, getLocalStorage } from '@shein/common-function'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { COUPON_MONITOR } from 'public/src/pages/common/coupon_package/analysis/index.js'
import { POP_UP_MONITOR } from './trackingEvents.js'
import { cccxEventBus } from 'public/src/pages/components/ccc/common/utils.js'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import Monitor from 'public/src/pages/common/home_common/monitor.js'

const { langPath, SiteUID, IS_SUPPORT_ONETRUST, IS_RW, CUSTOM_PRIVACY_SUPPORT } = gbCommonInfo
window._GB_PopUpModalEventCenter_ = new Vue()
let startGlobPopupTime = 0 // 初始时间
let ENABLED_TIMEOUT_FLAG = 0 // 是否启动了超时机制 0:正常启动 1:启动了超时机制
let NON_INIT_POPUP_STR = '' // 未初始化完成的弹窗名称 
let EVERY_POPUP_TIME = {} // 记录每个弹窗的初始化时间

/**
 * 是否在24小时之内
 * @param {*} time 需要判断的时间戳
 * @returns 
 */
const checkWithin24Hours = (time) => {
  if (!time) return false 

  const onDay = 1000 * 24 * 60 * 60
  const now = Date.now()
  return time && now - time < onDay
}
/**
 * 检查 选品 真实列表页券是否在24小时之内显示过，如果显示过则需要剔除
 * @param {String[]} cateCouponList 分类券券码列表
 * @returns {String[]} 剔除24小时之内显示过的券的结果
 */
const filterCouponWithin24Hours = (cateCouponList, maxexposelimit = 1) => {
  if (!cateCouponList?.length) return []
  
  const member_id = UserInfoManager.get({ key: 'memberId', actionType: 'globPopupModal/filterCouponWithin24Hours' }) ?? ''

  let lastShowTime = localStorage.getItem(`selectListDisplayedCoupon_${member_id}`)
  if (!lastShowTime) {
    return cateCouponList
  }

  lastShowTime = JSON.parse(lastShowTime)
  if ((typeof lastShowTime !== 'object') || Object.keys(lastShowTime).length === 0) return cateCouponList
  return cateCouponList.filter((couponCode) => {
    if (!lastShowTime?.[couponCode] || !Array.isArray(lastShowTime[couponCode]) || !lastShowTime[couponCode]?.length) return true
    const displayCount24Hours = lastShowTime[couponCode].filter((time) => {
      return checkWithin24Hours(time)
    })?.length ?? 0
    return displayCount24Hours < maxexposelimit
  })
}

/**
 * 检查一键购是否需要弹窗
 * @returns 
 */
const checkOneClickPopup = () => {
  const localStorageKey = `oneClickPayGuid_${UserInfoManager.get({ key: 'memberId', actionType: 'globPopupModal/OneClickPayGuide' }) || ''}`

  const showState = localStorage.getItem(localStorageKey)

  const bannerTag = window?.gbCommonInfo?.contextForSSR?.bannerTag

  return !!bannerTag?.showTag && bannerTag.type === 'oneClickPay' && !showState
}

/**
 * 检查globPopupModalInfo是否有isWait为true的
 * 有则说明有全局弹窗需要显示
 */
function checkIsWait(obj) {
  let filterObj = JSON.parse(JSON.stringify(obj))
  if(filterObj.onetrust) {
    delete filterObj.onetrust
  }
  if(filterObj.message) {
    delete filterObj.message
  }
  return Object.values(filterObj).some(item => item?.isWait)
}

let lastPopupModalName = ''
const MARKETING = 'marketing'
const SHEIN_PUSH = 'sheinPush'
let isSheinPushDone = false
// 后续逻辑会依据abt调整onetrust顺序
let globPopupModalSort = ['forceUpdatePwd', MARKETING, 'ip', 'mutiplePartPrivacy', 'mutipleLang', 'guideInstall', 'coupon', 'couponRevisit', 'quickRegister', 'onetrust', SHEIN_PUSH, 'message'] // 全局弹窗顺序,从左到右
// 全局弹窗队列的状态map, 初始化一下
let globPopupModalInfo = globPopupModalSort.reduce((val, key) => {
  val[key] = { loading: true, isWait: false }
  return val
}, {})
window._GB_PopUpModalEventCenter_.globPopupModalInfo = globPopupModalInfo


// 全局弹窗队列的name集合
let globDialogQueueNames = []

// 处理一些特殊情况的条件
function dealSpecialCondition () {
  const info = globPopupModalInfo
  // 如果同时存在隐私政策弹窗和ip弹窗，只显示ip弹窗
  if (info['ip'] && info['ip'].isWait && info['privacy'] && info['privacy'].isWait) {
    info['privacy'].isWait = false
  }
}

// 劵包确保604事件只触发一次
let onceMonitor = true
// load_time只触发一次
let onceLoadTime = true
// 在相关modal状态初始化回来之后添加入待显示队列
// 要压入 + 没有这个队列 = 初始
// 打开以后 关闭等操作 => done
window._GB_PopUpModalEventCenter_.$on('global-pop-modal-queue', (data) => {
  const { name, isWait = false } = data

  if (name === 'coupon') {
    COUPON_MONITOR.normaMonitor({
      code: '603',
      show_judge: 1,
      remark: '首页队列接收成功'
    })
  }

  globPopupModalInfo[name] = { loading: false, isWait }
  // 增加每个初始化完成的弹窗的从初始到完成的时间
  EVERY_POPUP_TIME[name] = new Date().getTime() - startGlobPopupTime
  // 如果所有的弹窗请求已完成初始化状态
  if (globPopupModalSort.every((k) => !globPopupModalInfo[k].loading)) {
    // 所有准备完成
    // 确定弹窗队列
    // 整体弹窗完成数据请求（一定会有）
    // 弹窗队列开始弹窗
  
    dealSpecialCondition()
    if(onceLoadTime) {
      onceLoadTime = false
      const endGlobPopupTime = new Date().getTime()
      const every_popup_time = Object.entries(EVERY_POPUP_TIME || {}).map(([key, value]) => `${key || ''}=${value || ''}`).join('|')
      POP_UP_MONITOR.normaMonitor({
        nonQueue: true, // 非队列埋点，正常上报，不依据abt来控制上报不上报
        load_time: endGlobPopupTime - startGlobPopupTime,
        every_popup_time,
        is_timeout: ENABLED_TIMEOUT_FLAG,
        remark: '弹窗队列启动所需时间（初始化完成）',
        event_name: 'expose_dq_loadtime'
      })
    }

    window._GB_PopUpModalEventCenter_.next('')

    if (globPopupModalSort.includes('coupon') && onceMonitor) {
      onceMonitor = false
      COUPON_MONITOR.normaMonitor({
        code: '604',
        show_judge: 1,
        remark: '首页队列初始化完成后是否存在券包'
      })
    }
  } 
})

/**
 * 加入全局弹窗队列
 * 提供给全局模块的加入队列方法,并且创建一个监听事件提供回调
 * @param name 弹窗模块文字
 * @param cb 回调函数
 */
let eventCallBackMap = {}
window._GB_PopUpModalEventCenter_.pushQueue = (name, cb) => {
  if(name === SHEIN_PUSH) {
    if(isSheinPushDone) return
    // shein push由于加载非常慢, 初始化时默认已加载, 此时如果加入空的弹窗队列, 需要弹出
    if(lastPopupModalName === null) {
      lastPopupModalName = ''
    }
  }
  if(!globDialogQueueNames?.includes(name)) {
    globDialogQueueNames.push(name)
  }
  // 弹窗加入队列的埋点逻辑
  POP_UP_MONITOR.operationMonitor({
    event_name: 'expose_dq_dialog_add',
    dialog_name: name,
    dialog_queue: globDialogQueueNames.join(','),
    remark: `${name} 添加到队列中`
  })
  Monitor.metricGlobPopup({
    tags: {
      track_type: '2', // dq_dialog_add
      alert_type: name,
    },
    message: `${name} dq_dialog_add`
  })

  window._GB_PopUpModalEventCenter_.$emit('global-pop-modal-queue', { isWait: true, name })
  const eventCallbackFn = (evtName) => {
    if (name === evtName && typeof cb === 'function') {
      cb()
    } else {
      if (name === evtName && name === 'coupon') {
        COUPON_MONITOR.errorMonitor({
          code: '636',
          show_judge: 1,
          remark: '全局队列劵包弹出回调类型错误'
        })
      }
    }
  }
  (eventCallBackMap[name] || (eventCallBackMap[name] = [])).push(eventCallbackFn)
  
  window._GB_PopUpModalEventCenter_.$on('global-pop-modal-show', eventCallbackFn)

  if (name === 'coupon' && !eventCallBackMap[name]) {
    COUPON_MONITOR.errorMonitor({
      code: '636',
      show_judge: 1,
      remark: '全局队列劵包弹出回调类型错误'
    })
  }
}

/**
 * 展示下一个全局弹窗
 * 上一个弹窗模块完成，调用下一个显示下一个弹窗模块
 * @param name 当前调用next方法的模块名称
 */
let hasAllDialogsFinished = false
window._GB_PopUpModalEventCenter_.next = (name) => {
  
  window._GB_PopUpModalEventCenter_.lastPopupModalName = name
  // 如果调用next的方法与正在展示的弹窗不一致，拦截
  if (name !== lastPopupModalName) {
    // 券包弹窗弹出前，上一个弹窗调用 next 方法名不是 coupon，则会被拦截掉
    // 不弹出券包弹窗
    if (name === 'coupon') {
      COUPON_MONITOR.errorMonitor({
        code: '640',
        show_judge: 1,
        remark: '上一个弹窗调用的next方法名与券包名不一致, 弹窗被拦截'
      })
    }
    return
  }
  // 更新上一个弹窗的状态
  if (lastPopupModalName) globPopupModalInfo[lastPopupModalName].isWait = false
  const nextName = nextModalName()
  if (lastPopupModalName) {
    // 在关闭弹窗后触发埋点
    POP_UP_MONITOR.operationMonitor({
      event_name: 'expose_dq_dialog_close',
      dialog_name: lastPopupModalName,
      remark: `${lastPopupModalName} 弹窗关闭`
    })
    Monitor.metricGlobPopup({
      tags: {
        track_type: '4', // dq_dialog_close
        alert_type: lastPopupModalName,
      },
      message: `${lastPopupModalName} dq_dialog_close`
    })
  }
  const hasModelNeedShow = checkIsWait(globPopupModalInfo || {})
  if(!hasAllDialogsFinished){
    POP_UP_MONITOR.operationMonitor({
      event_name: 'expose_dq_all_dialog_finish',
      dialog_queue: globDialogQueueNames.join(','), 
      remark: `弹窗完成所有请求数据` 
    })
    hasAllDialogsFinished = true
  }
  lastPopupModalName = nextName
  if(nextName && name === ''){
    // 上报弹窗队列开始弹窗的埋点
    POP_UP_MONITOR.operationMonitor({
      event_name: 'expose_dq_start',
      dialog_name: nextName, 
      dialog_queue: globDialogQueueNames.join(','),
      is_timeout: ENABLED_TIMEOUT_FLAG,
      timeout_dialog: NON_INIT_POPUP_STR,
      remark: '弹窗队列开始弹窗'
    })

  }
  setTimeout(() => {
    window._GB_PopUpModalEventCenter_.$emit('global-pop-modal-show', nextName)
    if (nextName) {
      // 在此处添加埋点逻辑
      POP_UP_MONITOR.operationMonitor({
        event_name: 'expose_dq_dialog_real_show',
        dialog_name: nextName, // 正在显示的弹窗名称
        dialog_queue: globDialogQueueNames.join(','), // 当前队列中的弹窗名称列表
        remark: `${nextName} 弹窗弹出`
      })
      Monitor.metricGlobPopup({
        tags: {
          track_type: '3', // dq_dialog_real_show
          alert_type: nextName,
        },
        message: `${nextName} dq_dialog_real_show`
      })
    }
  }, 0)

  // 判断全局弹窗是否全部弹完了
  if(!hasModelNeedShow) { // 全弹完了
    const eventObj = {
      endModel: !hasModelNeedShow,
    }
    cccxEventBus?.emit?.('ccc-global-popup-modal', eventObj)
  }
  
  // TODO:temp 劵包弹窗前是否还有前置弹窗
  const globPopupArray = []
  for (let i = 0; i < globPopupModalSort.length; i++) {
    const key = globPopupModalSort[i]
    if (globPopupModalInfo[key].isWait) {
      globPopupArray.push(key)
    }
    if (key === 'coupon') {
      break
    }
  }
  
  if (globPopupArray.length > 1 && globPopupArray.includes('coupon')) {
    COUPON_MONITOR.operationMonitor({
      code: '664',
      show_judge: 1,
      remark: `存在优先级更高的营销活动 - ${globPopupArray?.toString()?.split('.')}`,
    })
  }
}

/**
 * 加载完成，但是不需要展示全局队列
 * selfClose 当前文件读取后关闭
 */
window._GB_PopUpModalEventCenter_.done = (name) => {
  if (!globPopupModalSort.includes(name)) return
  globPopupModalInfo[name].loading = false
  // 初始化后判断是否有全局弹窗显示
  const hasModelNeedShow = checkIsWait(globPopupModalInfo || {})
  const windowHasPopModalShow = window._GB_PopUpModalEventCenter_?.isHasPopModalShow
  if(hasModelNeedShow && !windowHasPopModalShow) {
    window._GB_PopUpModalEventCenter_.isHasPopModalShow = true
  }
  
  window._GB_PopUpModalEventCenter_.$emit('global-pop-modal-queue', { isWait: false, name })
  if (eventCallBackMap[name]) {
    eventCallBackMap[name].forEach((fn) => {
      window._GB_PopUpModalEventCenter_.$off('global-pop-modal-show', fn)
    })
    eventCallBackMap[name] = []
  }
}

function nextModalName () {
  for (let i = 0; i < globPopupModalSort.length; i++) {
    const key = globPopupModalSort[i]
    if (globPopupModalInfo[key].isWait) {
      return key
    }
  }
  return null
}


function isShowMutipleLangPop () {
  const supportMutipleLangModal = ['pwma']
  if (!supportMutipleLangModal.includes(SiteUID)) return false
  if (getLocalStorage(`no_mutiple_lang_${SiteUID}_pop`)) return false
  return true
}

/**
 * @description 过滤不需要弹窗的页面
 * @returns {Boolean}
 */
function isCloseAllPop ()  {
  const pages = ['transit', 'friendship_coupon', 'couponActivity', '/scan/care_label', '/credentials', '/ip-complaint']
  const fhost = [
    'https://ccc-test01.biz.sheincorp.cn', 'https://ccc-test02.biz.sheincorp.cn', 'https://ccc-store-test01.biz.sheincorp.cn', 'https://ccc-store-test02.biz.sheincorp.cn',
    'https://ccc-gray01.biz.sheincorp.cn', 'https://ccc-gray02.biz.sheincorp.cn', 'https://ccc-store-gray01.shein.com', 'https://ccc-store-gray02.shein.com',
    'https://ccc.biz.sheincorp.cn', 'https://ccc-store.shein.com'
  ]
  let flag = false
  pages.forEach(item => {
    if (location.pathname.indexOf(item) > -1) {
      flag = true
    }
  })
  let url = ''
  if (parent !== window) {
    try {
      url = parent.location.href
    } catch (error) {
      url = document.referrer
    }
    console.log('parent-url++', url)
  }
  if (fhost.some(val => url.indexOf(val) != -1)) {
    flag = true
  }
  return flag
}

/**
 * 超时机制处理
 * 根据abt配置，配置了超时时间则在超时时间内未初始化完成的弹窗，手动初始化done
 * 目的：不会因为一个弹窗的无限超时导致其他弹窗无法正常弹窗，弹窗队列无法启动
 */
function handleTimeoutFn ({ abt = {} }) {
  const { HomePopup } = abt
  const timeoutVal = Number(HomePopup?.p?.queue_timeout)
  // const timeoutVal = 5
  if(!HomePopup?.p?.queue_timeout || isNaN(timeoutVal) || timeoutVal <= 0) return
  const timerId = setTimeout(() => {
    ENABLED_TIMEOUT_FLAG = 1
    // 开始进入超时逻辑
    // 获取所有还未初始化完成弹窗，标识符为loading=true
    const nonInitPopupList = Object.keys(window._GB_PopUpModalEventCenter_?.globPopupModalInfo || {}).filter(modalName => {
      return window._GB_PopUpModalEventCenter_.globPopupModalInfo[modalName].loading === true
    })
    NON_INIT_POPUP_STR = nonInitPopupList.join(',')
    POP_UP_MONITOR.normaMonitor({
      nonQueue: true, // 非队列埋点，正常上报，不依据abt来控制上报不上报
      timeout_dialog: NON_INIT_POPUP_STR,
      remark: '弹窗队列超时启动',
      event_name: 'expose_dq_timeout'
    })
    if(nonInitPopupList?.length > 0) {
      nonInitPopupList.forEach(modalName => {
        window._GB_PopUpModalEventCenter_.done(modalName)
      })
    }
    clearTimeout(timerId)
  }, timeoutVal * 1000)
}

// 初始化全局弹窗
export async function loadGlobalPopModule () {
  // 初始化时间-不要轻易改位置
  startGlobPopupTime = new Date().getTime()
  // abt
  const { CouponbagUpdate, SearchListCoupon, SearchSceneCoupon, HomePopup } = await getUserAbtData()
  POP_UP_MONITOR.operationMonitor({
    remark: '弹窗队列初始化完成，准备启动',
    event_name: 'expose_dq_launch'
  })
  COUPON_MONITOR.normaMonitor({
    code: '608',
    remark: '弹窗队列开始初始化',
  })

  // 强制改密弹窗
  import(/* webpackChunkName: "forceUpdatePwd-module" */ './forceUpdatePwd')
  
  // webpush自定义弹窗
  import(/* webpackChunkName: "sheinPush-modele" */ './sheinPush')
  
  if (isCloseAllPop()) {
    COUPON_MONITOR.operationMonitor({
      code: '665',
      show_judge: 0,
      remark: '命中isCloseAllPop为true',
    })
    return
  }

  if (window.isClientBot) {
    COUPON_MONITOR.operationMonitor({
      code: '665',
      show_judge: 0,
      remark: '命中window.isClientBot为true',
    })
    return
  }
  // 依据abt调整onetrust的顺序
  if(HomePopup?.param?.onetrust_pwa_priority === '1') { // onetrust弹窗顺序置顶
    const index = globPopupModalSort.indexOf('onetrust')
    if (index !== -1) {
      globPopupModalSort.splice(index, 1)
    }
    globPopupModalSort.unshift('onetrust')
  }
  
  handleTimeoutFn({
    abt: { HomePopup },
  })

  // 初始化置空，isHasPopModalShow没有全局弹窗显示
  window._GB_PopUpModalEventCenter_.isHasPopModalShow = false

  /**
   *  美国短信弹窗引入
   */
  //   if (['us'].includes(lang)) {
  //     import(/* webpackChunkName: "ussms-module" */'./attentive')
  //   } else {
  //     window._GB_PopUpModalEventCenter_.done('ussms')
  //   }

  /**
   *  IP跳转弹窗引入
   */
  if (!['marg', 'mhk'].includes(SiteUID)) {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'ip',
      remark: 'IP跳转弹窗启动',
      event_name: 'expose_dq_ip_start'
    })
    import(/* webpackChunkName: "ip-jump-dialog-module" */'./ip_jump_dialog')
  } else {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'ip',
      remark: 'IP跳转弹窗未启动',
      event_name: 'expose_dq_ip_end'
    })
    window._GB_PopUpModalEventCenter_.done('ip', true)
  }

  /**
	 *	语言选择弹窗
	 */
   if (isShowMutipleLangPop()) {
    POP_UP_MONITOR.operationMonitor({
      remark: '语言选择弹窗启动',
      event_name: 'expose_dq_mutiple_lang_start'
    })
    import(/* webpackChunkName: "mutilple-lang-module" */'./switchLanguage').then(() => {})
  } else {
    POP_UP_MONITOR.operationMonitor({
      remark: '语言选择弹窗未启动',
      event_name: 'expose_dq_mutiple_lang_end'
    })
    globPopupModalInfo['mutipleLang'] && (globPopupModalInfo['mutipleLang'].loading = false)
  }
  
  /**
   * One Trust cookie合规 模块引入
   */
  if(CUSTOM_PRIVACY_SUPPORT.includes(SiteUID)){
    import(/* webpackChunkName: "privacyPop-module" */
      './privacyPop').then(() => {})
    window._GB_PopUpModalEventCenter_.done('onetrust', true)
  }else{
    if (IS_SUPPORT_ONETRUST) {
      POP_UP_MONITOR.operationMonitor({
        dialog_name: 'onetrust',
        remark: 'One Trust弹窗启动',
        event_name: 'expose_dq_onetrust_start'
      })
      import(/* webpackChunkName: "onetrust-module" */
        './onetrust').then(() => {})
    } else {
      POP_UP_MONITOR.operationMonitor({
        dialog_name: 'onetrust',
        remark: 'One Trust弹窗未启动',
        event_name: 'expose_dq_onetrust_end'
      })
      window._GB_PopUpModalEventCenter_.done('onetrust', true)
    }
  }

  /**
   * 优惠券包模块引入
   */
  // const
  const isShouldImportCoupon = () => {

    const isAdPage = location.href.includes('landing_page_id') && (location.href.includes('test=5051') || location.href.includes('test=5081'))

    // 设置过全局变量不需要卡券
    if (window.notNeedCouponPackage) {
      COUPON_MONITOR.operationMonitor({
        code: '665',
        show_judge: 0,
        remark: '命中window.notNeedCouponPackage为true',
      })
      return false
    }

    // 屏蔽特定广告页
    if (isAdPage) {
      COUPON_MONITOR.operationMonitor({
        code: '665',
        show_judge: 0,
        remark: '有登录弹窗/主动屏蔽的页面:landing_page_id&test=5051/5081',
      })
      return false  
    }

    // 需要屏蔽的页面
    if (location.href.indexOf('user/login') > -1
      || location.href.indexOf('user/prime') > -1
      || location.href.indexOf('user/sheinsaver') > -1
      || location.href.indexOf('user/primefreetrial') > -1
    ) {
      COUPON_MONITOR.operationMonitor({
        code: '665',
        show_judge: 0,
        remark: `有登录弹窗/主动屏蔽的页面:${location?.pathname}`,
      })
      return false
    }
    const pathname = location.pathname
    
    // 列表页
    const matchList = [
      /daily-new\.html$/,
      /sellingpoint-(\d+)-(\d+)-.+\.html$/,
      /.+-c-(\d+)\.html$/, // 实体分类
      /((\w+)\/)?.+-sc-([A-Za-z0-9]+)\.html$/, // 选品分类
      /([a-z0-9]+-picks|romwe-[a-z]+|promo-discount)\.html$/,
      /coupon-product\.html$/, // 优惠券产品列表
      /\/flash-sale.html$/,
      /pdsearch\/([^\/]+)\/?$/
    ]
  
    // CouponbagUpdate 区分是否走弹出优化逻辑
    if (CouponbagUpdate?.param?.CouponbagUpdate === 'A') {
      
      // 列表匹配 - 列表/搜索品类劵跟全局劵包互斥
      if (!!matchList.find(v => v.test(pathname))) {
        // SearchListCoupon、 SearchSceneCoupon 区分是否有列表品类劵
        if ((!isLogin() && SearchListCoupon?.param?.pos) || isLogin() && SearchSceneCoupon?.param?.SearchSceneCouponPos) {
          COUPON_MONITOR.operationMonitor({
            code: '665',
            show_judge: 0,
            remark: '列表/搜索结果页命中ABT参数SearchListCoupon或SearchSceneCoupon',
          })
          return false
        }
      }

      return true
    } else {
      // 首页
      if (pathname === `${langPath}/`) {
        return true
      }

      // 列表页匹配
      if (!!matchList.find(v => v.test(pathname))) {

        // 一键购是否需要弹窗
        if (checkOneClickPopup()) {
          COUPON_MONITOR.operationMonitor({
            code: '665',
            show_judge: 0,
            remark: '列表/搜索结果页命中一键购新人弹窗',
          })
          return false
        }

        // 显示列表券不显示券包弹窗
        const cateCoupon = window?.gbCommonInfo?.contextForSSR?.cccConfig?.cateCoupon
        if (!cateCoupon?.length) {
          return true
        } else {
          const showCateCoupon = filterCouponWithin24Hours(cateCoupon)
          if (!showCateCoupon?.length) {
            return true 
          } else {
            COUPON_MONITOR.operationMonitor({
              code: '665',
              show_judge: 1,
              remark: '列表/搜索结果页命中ABT参数SearchListCoupon或SearchSceneCoupon',
            })
          }
        }
      }
      // 详情页
      if (/.*-p-(\d+)-cat-(\d+).html/.test(pathname) || /.*-p-(\d+).html/.test(pathname)) {
        return true
      }
      // 专题页 IOM-12646
      if (pathname.startsWith(`${langPath}/campaign`) && gbCommonInfo?.contextForSSR?.isCouponPopUp == 1)  {
        return true
      }
      // 店铺页
      if (/store\/home$/.test(pathname)) {
        COUPON_MONITOR.operationMonitor({
          code: '665',
          show_judge: 0,
          remark: '店铺页不展示全局券包弹窗',
        })
        return false
      }
      return false
    }
  }

  if (isShouldImportCoupon()) {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'isShould',
      remark: 'isShould弹窗启动',
      event_name: 'expose_dq_isShould_start'
    })
    COUPON_MONITOR.normaMonitor({
      code: '609',
      show_judge: 1,
      remark: '开始加载券包弹窗资源'
    })
    import(/* webpackChunkName: "coupon-module" */ './coupon_package')
      .then(() => {
        COUPON_MONITOR.normaMonitor({
          code: '610',
          show_judge: 1,
          remark: '券包弹窗资源加载成功'
        })
      })
      .catch(() => {
        window._GB_PopUpModalEventCenter_.done('coupon', true)
        COUPON_MONITOR.errorMonitor({
          code: '641',
          show_judge: 1,
          remark: '券包弹窗资源加载失败'
        })
      })
  } else {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'isShould',
      remark: 'isShould弹窗未启动',
      event_name: 'expose_dq_isShould_end'
    })
    window._GB_PopUpModalEventCenter_.done('coupon', true)
  }
  
  /**
   * 用劵提醒弹窗引入
   */
  function isShouldImportCouponRevisit () {
    // 需要屏蔽的页面
    if (location.href.indexOf('user/login') > -1
      || location.href.indexOf('user/prime') > -1
      || location.href.indexOf('user/sheinsaver') > -1
      || location.href.indexOf('user/primefreetrial') > -1
    ) {
      return false
    }
    
    const isAdPage = location.href.includes('landing_page_id') && (location.href.includes('test=5051') || location.href.includes('test=5081'))
    if (isAdPage) {
      return false
    }

    return isLogin() && !IS_RW
  }
  if (isShouldImportCouponRevisit()) {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'couponRevisit',
      remark: '用劵提醒弹窗启动',
      event_name: 'expose_dq_couponRevisit_start'
    })
    import(/* webpackChunkName: "coupon-revisit-module" */ './coupon_revisit_package')
  } else {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'couponRevisit',
      remark: '用劵提醒弹窗未启动',
      event_name: 'expose_dq_couponRevisit_end'
    })
    window._GB_PopUpModalEventCenter_.done('couponRevisit', true)
  }
  

  /**
   * 快速注册弹窗引入
   */
  if (!isLogin() && parseInt(getLocalStorage('hide_download_bar'), 10) !== 1) {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'quickRegister',
      remark: '快速注册弹窗启动',
      event_name: 'expose_dq_quick_register_start'
    })
    import(/* webpackChunkName: "quick_register" */ './quick_register')
  } else {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'quickRegister',
      remark: '快速注册弹窗未启动',
      event_name: 'expose_dq_quick_register_end'
    })
    window._GB_PopUpModalEventCenter_.done('quickRegister', true)
  }

  function isImportMarketingPop () {
    const href = location.href
    const pathname = location.pathname
    
    // 参数限制
    if (href.indexOf('utm_campaign=GM_Share') == -1) return false
    // 开放的路由
    const openRoutes = [ // 必须是正则模式
      /\/.+-p-(\d+)(-cat-(\d+))?\.html/, // 商详
      // TODO-requestPath-HACK
      /\/product-quickview-(\d+)\.html/, // 商详快速加车
      /\/(rw-)?invite$/ // 邀请页
    ]
    return openRoutes.some((v) => v.test(pathname))
  }
  if (getLocalStorage('marketing_block_pop') != 1 && isImportMarketingPop()) {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'marketing',
      remark: 'marketing弹窗启动',
      event_name: 'expose_dq_marketing_popup_start'
    })
    import(/* webpackChunkName: "marketing_pop_module" */ './marketing/')
  } else {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'marketing',
      remark: 'marketing弹窗未启动',
      event_name: 'expose_dq_marketing_popup_end'
    })
    window._GB_PopUpModalEventCenter_.done('marketing', true)
  }

  /**
   *  站内信首页弹框
   */
  function isImportMessageDialog () {
    // 如果用户登录情况下 进入首页 就加在组件
    return isLogin()
  }
  if (isImportMessageDialog()) {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'message',
      remark: '站内信首页弹框启动',
      event_name: 'expose_dq_message_dialog_start'
    })
    import(/* webpackChunkName: "message-dialog-module" */'./message_dialog')
  } else {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'message',
      remark: '站内信首页弹框未启动',
      event_name: 'expose_dq_message_dialog_end'
    })
    window._GB_PopUpModalEventCenter_.done('message', true)
  }

  // 多主体隐私政策弹窗
  function isImportMutiplePartModule () {
    const pathname = location.pathname
    const ids = [282, 488, 399] // 隐私文章页不弹窗
    if (ids.some((v) => pathname.indexOf(`-a-${v}.html`) > -1 )) {
      return false
    }
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'mutiplePartPrivacy',
      remark: '多主体隐私政策弹窗启动',
      event_name: 'expose_dq_MutiplePart_start'
    })
    return isLogin() && window?.sessionStorage?.getItem('mutiplePartPrivacy') != 1
  }
  isImportMutiplePartModule() ? 
    import(/* webpackChunkName: "mutiplePartPrivacy-module" */'./mutiplePartPrivacy') : 
    window._GB_PopUpModalEventCenter_.done('mutiplePartPrivacy', true) && POP_UP_MONITOR.operationMonitor({
      dialog_name: 'mutiplePartPrivacy',
      remark: '多主体隐私政策弹窗未启动',
      event_name: 'expose_dq_MutiplePart_end'
    })

  
  // 新用户引导跳转下载商店弹窗
  function isShowGuideInstallDialog() {
    if (IS_RW) return false
    const pop_up01 = getQueryString({ key: 'pop_up01' }) || ''
    const pop_up02 = getQueryString({ key: 'pop_up02' }) || ''
    return !!pop_up01 && !!pop_up02
  }
  if(isShowGuideInstallDialog()) {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'guideInstall',
      remark: '新用户引导跳转下载商店弹窗启动',
      event_name: 'expose_dq_guide_install_start'
    })
    window.swHolder = new Promise(r => window._swHolder = r)
    import(/* webpackChunkName: "guide_install" */'./guide_install')
  } else {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: 'guideInstall',
      remark: '新用户引导跳转下载商店弹窗未启动',
      event_name: 'expose_dq_guide_install_end'
    })
    window._GB_PopUpModalEventCenter_.done('guideInstall',  true)
  }

  /**
    * sheinPush弹窗引入
    */
  function isShouldImportSheinPush () {
    // 据marketing调整push弹窗的位置
    // const webPushOrderBeforMarketing = HomePopup?.param?.pushPopupSort_pwa == '0'
    // let onetrustIdx = -1 
    // let marketingIdx = -1
    // // update popup info
    // for (let i = 0; i < globPopupModalSort.length; i++) {
    //   const key = globPopupModalSort[i]
    //   if(key === 'onetrust') {
    //     onetrustIdx = i
    //   }
    //   if(key === MARKETING) {
    //     marketingIdx = i
    //   }
    // }

    // if(onetrustIdx < marketingIdx) {
    //   if(webPushOrderBeforMarketing) {
    //     // marketing前面插入
    //     globPopupModalSort.splice(marketingIdx, 0, SHEIN_PUSH)
    //   } else {
    //     // marketing后面插入
    //     globPopupModalSort.splice(marketingIdx + 1, 0, SHEIN_PUSH)
    //   }
    // } else {
    //   const webPushOrderAfterOnetrust = webPushOrderBeforMarketing
    //   if(webPushOrderAfterOnetrust) {
    //     // onetrust后面插入
    //     globPopupModalSort.splice(onetrustIdx + 1, 0, SHEIN_PUSH)
    //   } else {
    //     // 最后一个插入
    //     globPopupModalSort.push(SHEIN_PUSH)
    //   }
    // }
    // 最后一个插入
    // globPopupModalSort.push(SHEIN_PUSH)
    // init shein web push
    globPopupModalInfo[SHEIN_PUSH] = { loading: false, isWait: false }

    let shouldImport = true

    const { ENTRY_PAGE_NAME } =  window.gbCommonInfo || {}
    const supportPage = ['page_home', 'page_goods_detail', 'page_category', 'page_daily_new', 'page_real_class', 'page_select_class', 'page_search', 'page_goods_group']
 
    if (!supportPage.includes(ENTRY_PAGE_NAME)) {
      shouldImport = false
    }
    return shouldImport 
  }

  // 小于 24小时
  const ONE_DAY_MS = 1000 * 60 * 60 * 24
  const isWithinLastDay = (timestamp) => !!timestamp && (Date.now() - timestamp < ONE_DAY_MS)

  const webpushShowLastTime = getLocalStorage(`webpushShowLastTime-${SiteUID}`) || null
  if(isShouldImportSheinPush() && !isWithinLastDay(webpushShowLastTime)) {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: SHEIN_PUSH,
      remark: 'sheinPush弹窗启动',
      event_name: 'expose_dq_web_push_start'
    })
  } else {
    POP_UP_MONITOR.operationMonitor({
      dialog_name: SHEIN_PUSH,
      remark: 'sheinPush弹窗未启动',
      event_name: 'expose_dq_web_push_end'
    })
    isSheinPushDone = true
    globPopupModalInfo[SHEIN_PUSH] && (globPopupModalInfo[SHEIN_PUSH].loading = false)
    window._GB_PopUpModalEventCenter_.done(SHEIN_PUSH, true)
  }
}
