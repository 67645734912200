const { LAYOUT } = gbCommonInfo
window.addEventListener('load', () => {
  requestIdleCallback(() => {
    if (LAYOUT === 'app') return
    // 按需加载
    const isLoaded = {
      category: false,
      search: false
    }
    const proxyEl = document.querySelector('.j-common-logo-header')
    if(proxyEl) {
      proxyEl.addEventListener('click', (event) => {
        if (event?.target?.matches('.j-dt-go-category')) {
          if (!isLoaded.category) {
            isLoaded.category = true
            import(/* webpackChunkName: "mpa-sidecategory" */ './category')
          }
        }
      })
    }
  })
})
